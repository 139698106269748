define("discourse/plugins/paczki-topic-custom-fields/discourse/templates/connectors/composer-fields/topic-custom-field-link-composer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert this.checkComposerType}}> 
    {{#unless this.isHidden}}
      <TopicCustomFieldLinkInput
        @fieldName={{this.fieldName}}
        @fieldValue={{this.fieldValue}}
        @onChangeField={{this.onChangeField}}
        class="topic-custom-field-input full"
        required="required"
      />
    {{/unless}}
  </div>
  */
  {
    "id": "ZDjni4jf",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"checkComposerType\"]]],null],[12],[1,\" \\n\"],[41,[51,[30,0,[\"isHidden\"]]],[[[1,\"    \"],[8,[39,2],[[24,0,\"topic-custom-field-input full\"],[24,\"required\",\"required\"]],[[\"@fieldName\",\"@fieldValue\",\"@onChangeField\"],[[30,0,[\"fieldName\"]],[30,0,[\"fieldValue\"]],[30,0,[\"onChangeField\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"did-insert\",\"unless\",\"topic-custom-field-link-input\"]]",
    "moduleName": "discourse/plugins/paczki-topic-custom-fields/discourse/templates/connectors/composer-fields/topic-custom-field-link-composer.hbs",
    "isStrictMode": false
  });
});