define("discourse/plugins/paczki-topic-custom-fields/discourse/components/topic-custom-field-link-input", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object", "@ember/object/computed", "@ember/service", "discourse-common/helpers/i18n", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _object, _computed, _service, _i18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { action } from "@ember/object";

  class TopicCustomFieldLinkInput extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.readOnly)("siteSettings.topic_custom_field_link")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    // urlPattern =
    //   /^(https?:\/\/)?([a-zA-Z0-9]+([-\w]*[a-zA-Z0-9])*\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;
    // usernamePattern = /^@[a-zA-Z0-9_]+$/;
    constructor() {
      super(...arguments);
      // this.showField = this.shouldShowField;
    }
    get isCategoryValid() {
      const allowedCategories = this.allowedCategories || [];
      const currentCategoryId = this.currentCategoryId;
      return allowedCategories.includes(currentCategoryId);
    }
    get allowedCategories() {
      return (this.siteSettings.topic_custom_field_categories || "").split("|").map(id => parseInt(id, 10)).filter(id => id);
    }
    static #_4 = (() => dt7948.n(this.prototype, "allowedCategories", [(0, _object.computed)("siteSettings.topic_custom_field_categories")]))();
    get currentCategoryId() {
      this.categoryId = this.composer.model.categoryId;
      return this.categoryId;
    }
    get isTopicEnabled() {
      return this.allowedCategories.includes(this.currentCategoryId);
    }
    get shouldShowField() {
      return this.isTopicEnabled && this.isCategoryValid;
    }
    // get isValidUrl() {
    //   return this.urlPattern.test(this.inputValue);
    // }
    // get isValidUsername() {
    //   return this.usernamePattern.test(this.inputValue);
    // }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShowField}}
          <Input
            @type="text"
            @value={{@fieldValue}}
            placeholder={{i18n
              "topic_custom_field_link.placeholder"
              field=this.fieldName
            }}
            ...attributes
            {{on "change" (action @onChangeField value="target.value")}}
          />
        {{/if}}
      
    */
    {
      "id": "85cWG2BH",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShowField\"]],[[[1,\"      \"],[8,[32,0],[[16,\"placeholder\",[28,[32,1],[\"topic_custom_field_link.placeholder\"],[[\"field\"],[[30,0,[\"fieldName\"]]]]]],[17,1],[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,3]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@type\",\"@value\"],[\"text\",[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&attrs\",\"@fieldValue\",\"@onChangeField\"],false,[\"if\",\"action\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/paczki-topic-custom-fields/discourse/components/topic-custom-field-link-input.js",
      "scope": () => [_component2.Input, _i18n.default, _modifier.on],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicCustomFieldLinkInput;
});