define("discourse/plugins/paczki-topic-custom-fields/discourse/initializers/topic-custom-field-initializer", ["exports", "@ember/object/computed", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _computed, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "topic-custom-field-intializer",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const CUSTOM_FIELDS = [siteSettings.topic_custom_field_price, siteSettings.topic_custom_field_link];
      CUSTOM_FIELDS.forEach(fieldName => {
        (0, _pluginApi.withPluginApi)("1.37.3", api => {
          api.serializeOnCreate(fieldName);
          api.serializeToDraft(fieldName);
          api.serializeToTopic(fieldName, `topic.${fieldName}`);
          const PLUGIN_ID = `topic-custom-field-input-${fieldName}`.toLowerCase();

          // NOTE: Mapear luego.
          api.modifyClass("component:topic-list-item", dt7948.p({
            pluginId: PLUGIN_ID,
            customFieldName: fieldName,
            customFieldValue: (0, _computed.alias)(`topic.${fieldName}`),
            showCustomField: value => !!value
          }, [["field", "showCustomField", [(0, _decorators.default)("customFieldValue")]]]));
        });
      }); // end
    }
  };
});