define("discourse/plugins/paczki-topic-custom-fields/discourse/initializers/extend-post-content-initializer", ["exports", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "@ember/template-factory"], function (_exports, _pluginApi, _renderGlimmer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-post-content-initializer",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const PRICE = siteSettings.topic_custom_field_price;
      const LINK = siteSettings.topic_custom_field_link;
      (0, _renderGlimmer.registerWidgetShim)("before-post-content", "div.custom-fields-container1", (0, _templateFactory.createTemplateFactory)(
      /*
        <CustomFieldsDisplay @model={{@data}}/>
      */
      {
        "id": "l6T5e0tJ",
        "block": "[[[8,[39,0],null,[[\"@model\"],[[30,1]]],null]],[\"@data\"],false,[\"custom-fields-display\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/paczki-topic-custom-fields/discourse/initializers/extend-post-content-initializer.js",
        "isStrictMode": false
      }));
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        api.decorateWidget("post-contents:before", helper => {
          const postNumber = helper.widget.attrs["post_number"];
          if (postNumber !== 1) {
            return;
          }
          const postModel = helper.getModel();
          if (!postModel) {
            return;
          }
          const topic = postModel.topic;
          if (!topic) {
            return;
          }
          const categoryId = topic.category_id;
          const postUserId = postModel.user_id;
          const price = topic[PRICE] || null;
          const urlOrHandle = topic[LINK] || null;
          if (!price) {
            return;
          }
          const data = {
            categoryId,
            postUserId,
            price,
            urlOrHandle
          };
          return helper.attach("before-post-content", data);
        });
      }); // end
    }
  };
});