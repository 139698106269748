define("discourse/plugins/paczki-topic-custom-fields/discourse/connectors/composer-fields/topic-custom-field-link-composer", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/models/composer"], function (_exports, _component, _tracking, _object, _computed, _service, _composer3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicCustomFieldLinkComposer extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isHidden", [_tracking.tracked], function () {
      return true;
    }))();
    #isHidden = (() => (dt7948.i(this, "isHidden"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_link")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "composerModel", [(0, _computed.alias)("args.outletArgs.model")]))();
    #composerModel = (() => (dt7948.i(this, "composerModel"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "post", [(0, _computed.alias)("composerModel.post")]))();
    #post = (() => (dt7948.i(this, "post"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "topic", [(0, _computed.alias)("composerModel.topic")]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    constructor() {
      super(...arguments);
      if (!this.composerModel[this.fieldName] && this.topic && this.topic[this.fieldName]) {
        this.composerModel.set(this.fieldName, this.topic[this.fieldName]);
      }
      this.fieldValue = this.composerModel.get(this.fieldName);
    }
    onChangeField(fieldValue) {
      this.composerModel.set(this.fieldName, fieldValue);
    }
    static #_8 = (() => dt7948.n(this.prototype, "onChangeField", [_object.action]))();
    checkComposerType() {
      if (this.post && this.post.firstPost === true) {
        this.isHidden = false;
        return false;
      }
      if (this.composerModel.action === _composer3.default.CREATE_TOPIC) {
        this.isHidden = false;
        return false;
      }
      return true;
    }
    static #_9 = (() => dt7948.n(this.prototype, "checkComposerType", [_object.action]))();
  }
  _exports.default = TopicCustomFieldLinkComposer;
});